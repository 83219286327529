import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';

import { GoogleAnalyticsService } from './google-analytics.service';
import { HttpClientModule } from '@angular/common/http';

import localeItCH from '@angular/common/locales/it-CH';
import { registerLocaleData } from '@angular/common';
import { STiDiventareScoutModule } from './sti-diventare-scout/sti-diventare-scout.module';
import { SharedModule } from './shared/shared.module';
import { STiScoutismoModule } from './sti-scoutismo/sti-scoutismo.module';
registerLocaleData(localeItCH);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SharedModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    STiDiventareScoutModule,
    STiScoutismoModule
  ],
  providers: [GoogleAnalyticsService, HttpClientModule,
    { provide: LOCALE_ID, useValue: "it_CH" }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
