import { Injectable } from '@angular/core';

import SezioniJson from '../../../assets/sezioni_2023.json'
import ZoneJson from '../../../assets/zone.json'
import SezioniMapJason from '../../../assets/sezioni_map.json'

import { SezioneSerataScout as Sezione, SezioneMap, Zona, Zona_ID } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DataSezioniService {
  private zone: Zona_ID[] = ['bellinzonese', 'locarnese', 'luganese', 'mendrisiotto'];

  constructor() { }


  filterByZona(zona: Zona_ID): Sezione[] {
    return SezioniJson
      .filter((sezione) => sezione.zona == zona)
      .map((data) => new Sezione(data))
      // .sort((a, b) => this.sortHelper(a) - this.sortHelper(b));
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getZona(zona: Zona_ID): Zona {
    return ZoneJson[zona];
  }


  getCategoriesIds(): Zona_ID[] {
    return this.zone;
  }

  getGeneric(): Zona {
    console.log('h');
    return <Zona>ZoneJson['generic'];
  }

  private columnLayout(data: any) {
    let a = [], b = [], c = [];
    data.forEach((v, i) => {

      switch (i % 3) {
        case 0:
          a.push(v);
          break;
        case 1:
          b.push(v);
          break;
        case 2:
          c.push(v);
          break;
      }
    });
    return a.concat(b, c);;
  }

  getAll() {
    const ord = SezioniJson
      .map((data) => new Sezione(data))
      //.sort((a, b) => a.name.localeCompare(b.name));
      .sort((a, b) => a.zona.localeCompare(b.zona) || this.sortHelper(a) - this.sortHelper(b));
    return this.columnLayout(ord);
    //.sort((a, b) => this.sortHelper(a) - this.sortHelper(b));
  }

  private sortHelper(data: Sezione) {
    let length = Object.values(data).toString().length;
    if (!data.serata_scout_lupetti || !data.serata_scout_esploratori) {
      length = 1000;
    }
    return length;
  }

  getSezioniMap() {
    return SezioniMapJason.map
      .map((data) => new SezioneMap(data));
  }
}