import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent, isPlatformBrowser } from '@angular/common';
import { DataSezioniService } from '../../sti-diventare-scout/services';
import { Zona, Zona_ID } from '../../sti-diventare-scout/models';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    categories: string[];

    constructor(public location: Location, private router: Router, private dataService: DataSezioniService,
        @Inject(PLATFORM_ID) private platformId: any) {
        this.categories = this.dataService.getCategoriesIds();
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.router.events.subscribe((event) => {
                this.isCollapsed = true;
                if (event instanceof NavigationStart) {
                    if (event.url != this.lastPoppedUrl)
                        this.yScrollStack.push(window.scrollY);
                } else if (event instanceof NavigationEnd) {
                    if (event.url == this.lastPoppedUrl) {
                        this.lastPoppedUrl = undefined;
                        window.scrollTo(0, this.yScrollStack.pop());
                    } else
                        window.scrollTo(0, 0);
                }
            });
        }
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
    }

    getCategory(id: Zona_ID): Zona {
        return this.dataService.getZona(id);
    }

    menuClass() {
        var e = this.location.prepareExternalUrl(this.location.path());

        // if (e === '#/diventare-scout' || e === '#/map') {
        //     return 'navbar-foto';
        // }
        return '';
    }

    bg_color() {
        let t = this.location.prepareExternalUrl(this.location.path());
        if (t.startsWith('#/zona/luganese')) {
            return 'luganese';
        } else if (t.startsWith('#/zona/mendrisiotto')) {
            return 'mendrisiotto';
        } else if (t.startsWith('#/zona/bellinzonese')) {
            return 'bellinzonese';
        } else if (t.startsWith('#/zona/locarnese')) {
            return 'locarnese';
        }
        return 'sti'

    }
}
