import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scout-ad-ogni-costo',
  templateUrl: './scout-ad-ogni-costo.component.html',
  styleUrls: ['./scout-ad-ogni-costo.component.css']
})
export class ScoutAdOgniCostoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
