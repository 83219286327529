import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branca-rover',
  templateUrl: './branca-rover.component.html',
  styleUrls: ['./branca-rover.component.css']
})
export class BrancaRoverComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
