import { Zona_ID } from "./zona"

interface SediJson {
    latitude: number
    longitude: number
}

interface SezioneJson {
    id: string
    name: string
    description?: string
    zona: Zona_ID
    target_castori?: boolean
    target_lupetti?: boolean
    target_esploratori?: boolean
    target_pionieri?: boolean
    target_rover?: boolean
    website?: string
    instagram?: string
    facebook?: string
    email?: string

    amigo_date?: string
    amigo_place?: string
    amigo_date_castori?: string
    amigo_place_castori?: string
    amigo_date_lupetti?: string
    amigo_place_lupetti?: string
    amigo_date_esploratori?: string
    amigo_place_esploratori?: string
    amigo_date_pionieri?: string
    amigo_place_pionieri?: string
    amigo_date_rover?: string
    amigo_place_rover?: string
}

interface SezioneMapJson {
    id: string
    name: string
    zona: Zona_ID
    latitude: string
    longitude: string
}

export class SezioneMap {
    id: string = ''
    name: string = ''
    zona: Zona_ID = ''
    latitude: number = 0
    longitude: number = 0
    constructor(obj: any) {
        let {
            id = '',
            name = '',
            zona = <Zona_ID>'',
            latitude = '',
            longitude = '',
        } = obj;
        this.id = id;
        this.name = name;
        this.zona = zona;
        this.latitude = parseFloat(latitude);
        this.longitude = parseFloat(longitude);
    }
}

export class Sezione {
    id: string
    name: string
    description?: string
    zona: Zona_ID
    target_castori?: boolean
    target_lupetti?: boolean
    target_esploratori?: boolean
    target_pionieri?: boolean
    target_rover?: boolean
    website?: string
    instagram?: string
    facebook?: string
    email?: string

    amigo_date?: string
    amigo_place?: string
    amigo_date_castori?: string
    amigo_place_castori?: string
    amigo_date_lupetti?: string
    amigo_place_lupetti?: string
    amigo_date_esploratori?: string
    amigo_place_esploratori?: string
    amigo_date_pionieri?: string
    amigo_place_pionieri?: string
    amigo_date_rover?: string
    amigo_place_rover?: string


    constructor(obj: any) {
        let {
            id = '',
            name = '',
            description = '',
            zona = '',
            target_castori = false,
            target_lupetti = false,
            target_esploratori = false,
            target_pionieri = false,
            target_rover = false,
            website = '',
            instagram = '',
            facebook = '',
            email = '',

            amigo_date = '',
            amigo_place = '',
            amigo_date_castori = '',
            amigo_place_castori = '',
            amigo_date_lupetti = '',
            amigo_place_lupetti = '',
            amigo_date_esploratori = '',
            amigo_place_esploratori = '',
            amigo_date_pionieri = '',
            amigo_place_pionieri = '',
            amigo_date_rover = '',
            amigo_place_rover = '',

        } = obj;
        this.id = id;
        this.name = name;
        this.description = description;
        this.zona = zona;
        this.target_castori = target_castori;
        this.target_lupetti = target_lupetti;
        this.target_esploratori = target_esploratori;
        this.target_pionieri = target_pionieri;
        this.target_rover = target_rover;
        this.website = website;
        this.instagram = instagram;
        this.facebook = facebook;
        this.email = email;

        this.amigo_date = amigo_date;
        this.amigo_place = amigo_place;
        this.amigo_date_castori = amigo_date_castori;
        this.amigo_place_castori = amigo_place_castori;
        this.amigo_date_lupetti = amigo_date_lupetti;
        this.amigo_place_lupetti = amigo_place_lupetti;
        this.amigo_date_esploratori = amigo_date_esploratori;
        this.amigo_place_esploratori = amigo_place_esploratori;
        this.amigo_date_pionieri = amigo_date_pionieri;
        this.amigo_place_pionieri = amigo_place_pionieri;
        this.amigo_date_rover = amigo_date_rover;
        this.amigo_place_rover = amigo_place_rover;
    }
}
