import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Zona, Zona_ID, Sezione } from '../../models';
import { DataSezioniService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-zona-display',
  templateUrl: './zona-display.component.html',
  styleUrls: ['./zona-display.component.css']
})
export class ZonaDisplayComponent implements OnInit {

  zona_id: Zona_ID;
  svg: string = ''
  sezioni: Sezione[] = [];
  zona: Zona = new Zona();
  mapLink: string = '../../map';

  closeResult: string;
  private fragment: string;

  constructor(private route: ActivatedRoute,
    private dataService: DataSezioniService,
    private modalService: NgbModal,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.fragment = this.route.snapshot.fragment
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.zona_id = <Zona_ID>params.get('zona');
      if (this.zona_id == null) {
        this.zona = this.dataService.getGeneric();
        this.sezioni = this.dataService.getAll();
        this.zona_id = 'all';
        this.mapLink = '../map';
        if (isPlatformBrowser(this.platformId)){
          this.http.get('./assets/img/sti/STi_zone_all.svg', { responseType: 'text' })
            .subscribe(data => this.svg = data);
          setTimeout(() => {
            this.scroll(this.fragment);
            this.fragment = '';
          }, 250);
        }
      }
      else {
        this.zona = this.dataService.getZona(this.zona_id);
        this.sezioni = this.dataService.filterByZona(this.zona_id);
        if (isPlatformBrowser(this.platformId)){
          this.http.get('./assets/img/sti/STi_zone_selected-'.concat(this.zona_id, '.svg'), { responseType: 'text' })
            .subscribe(data => this.svg = data);
          setTimeout(() => {
            this.scroll(this.fragment);
            this.fragment = '';
          }, 250);
        }
      }
    });
  }

  scroll(id) {
    if (!!id) {
      const elmnt = document.getElementById(id);
      if (!!elmnt) {
        elmnt.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    }

  }

  showDetailsModal(sezione: Sezione) {
    return false;
  }

  open(content) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getCategory(zona_id: Zona_ID): Zona {
    return this.dataService.getZona(zona_id);
  }

}
