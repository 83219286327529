import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Zona_ID, Zona } from '../../models';
import { DataSezioniService } from '../../services';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-zone-display',
  templateUrl: './zone-display.component.html',
  styleUrls: ['./zone-display.component.css']
})
export class ZoneDisplayComponent implements OnInit {
  svg: string = ''
  zone: Zona_ID[];
  constructor(private dataService: DataSezioniService,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.zone = this.dataService.getCategoriesIds();
    if (isPlatformBrowser(this.platformId)){
      this.http.get('./assets/img/sti/STi_zone_all.svg', { responseType: 'text' })
        .subscribe(data => this.svg = data);
    }
  }

  ngOnInit(): void {
  }

  getZona(id: Zona_ID): Zona {
    return this.dataService.getZona(id);
  }

}
