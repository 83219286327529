import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrancaCastoriComponent } from './components/branca-castori/branca-castori.component';
import { BrancaEsploratoriComponent } from './components/branca-esploratori/branca-esploratori.component';
import { BrancaLupettiComponent } from './components/branca-lupetti/branca-lupetti.component';
import { BrancaPionieriComponent } from './components/branca-pionieri/branca-pionieri.component';
import { BrancaRoverComponent } from './components/branca-rover/branca-rover.component';
import { ScopiScoutismoComponent } from './components/scopi-scoutismo/scopi-scoutismo.component';
import { ScoutAdOgniCostoComponent } from './components/scout-ad-ogni-costo/scout-ad-ogni-costo.component';
import { SilverScoutComponent } from './components/silver-scout/silver-scout.component';
import { StoriaScoutismoComponent } from './components/storia-scoutismo/storia-scoutismo.component';


const routes: Routes = [
  { path: '', component: ScopiScoutismoComponent },
  { path: 'castori', component: BrancaCastoriComponent },
  { path: 'lupetti', component: BrancaLupettiComponent },
  { path: 'esploratori', component: BrancaEsploratoriComponent },
  { path: 'pionieri', component: BrancaPionieriComponent },
  { path: 'rover', component: BrancaRoverComponent },
  { path: 'silver-scout', component: SilverScoutComponent },
  { path: 'soc', component: ScoutAdOgniCostoComponent },
  { path: 'storia', component: StoriaScoutismoComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class STiScoutismoRoutingModule { }
