import { Zona_ID } from "./zona"

interface SediJson {
    latitude: number
    longitude: number
}

interface SezioneSerataScoutJson {
    id: string
    name: string
    description?: string
    zona: Zona_ID
    target_castori?: boolean
    target_lupetti?: boolean
    target_esploratori?: boolean
    target_pionieri?: boolean
    target_rover?: boolean
    website?: string
    instagram?: string
    facebook?: string
    email?: string

    serata_scout_lupetti?: boolean
    serata_scout_lupetti_ritrovo?: string
    serata_scout_lupetti_ora_inizio?: string
    serata_scout_lupetti_ora_fine?: string
    serata_scout_lupetti_link_form?: string

    serata_scout_esploratori?: boolean
    serata_scout_esploratori_ritrovo?: string
    serata_scout_esploratori_ora_inizio?: string
    serata_scout_esploratori_ora_fine?: string
    serata_scout_esploratori_link_form?: string

}

export class SezioneSerataScout {
    id: string
    name: string
    description?: string
    zona: Zona_ID
    target_castori?: boolean
    target_lupetti?: boolean
    target_esploratori?: boolean
    target_pionieri?: boolean
    target_rover?: boolean
    website?: string
    instagram?: string
    facebook?: string
    email?: string

    serata_scout_lupetti?: boolean
    serata_scout_lupetti_ritrovo?: string
    serata_scout_lupetti_ora_inizio?: string
    serata_scout_lupetti_ora_fine?: string
    serata_scout_lupetti_link_form?: string

    serata_scout_esploratori?: boolean
    serata_scout_esploratori_ritrovo?: string
    serata_scout_esploratori_ora_inizio?: string
    serata_scout_esploratori_ora_fine?: string
    serata_scout_esploratori_link_form?: string

    castori_inizio?: string
    castori_fine?: string
    castori_ritrovo?: string
    castori_osservazioni?: string
    lupetti_inizio?: string
    lupetti_fine?: string
    lupetti_ritrovo?: string
    lupetti_osservazioni?: string
    esploratori_inizio?: string
    esploratori_fine?: string
    esploratori_ritrovo?: string
    esploratori_osservazioni?: string
    pionieri_inizio?: string
    pionieri_fine?: string
    pionieri_ritrovo?: string
    pionieri_osservazioni?: string

    constructor(obj: any) {
        let {
            id = '',
            name = '',
            description = '',
            zona = '',
            target_castori = false,
            target_lupetti = false,
            target_esploratori = false,
            target_pionieri = false,
            target_rover = false,
            website = '',
            instagram = '',
            facebook = '',
            email = '',

            serata_scout_lupetti = false,
            serata_scout_lupetti_ritrovo = '',
            serata_scout_lupetti_ora_inizio = '',
            serata_scout_lupetti_ora_fine = '',
            serata_scout_lupetti_link_form = '',
            serata_scout_esploratori = false,
            serata_scout_esploratori_ritrovo = '',
            serata_scout_esploratori_ora_inizio = '',
            serata_scout_esploratori_ora_fine = '',
            serata_scout_esploratori_link_form = '',

            castori_inizio = '',
            castori_fine = '',
            castori_ritrovo = '',
            castori_osservazioni = '',
            lupetti_inizio = '',
            lupetti_fine = '',
            lupetti_ritrovo = '',
            lupetti_osservazioni = '',
            esploratori_inizio = '',
            esploratori_fine = '',
            esploratori_ritrovo = '',
            esploratori_osservazioni = '',
            pionieri_inizio = '',
            pionieri_fine = '',
            pionieri_ritrovo = '',
            pionieri_osservazioni = '',

        } = obj;
        this.id = id;
        this.name = name;
        this.description = description;
        this.zona = zona;
        this.target_castori = target_castori;
        this.target_lupetti = target_lupetti;
        this.target_esploratori = target_esploratori;
        this.target_pionieri = target_pionieri;
        this.target_rover = target_rover;
        this.website = website;
        this.instagram = instagram;
        this.facebook = facebook;
        this.email = email;

        this.serata_scout_lupetti = serata_scout_lupetti
        this.serata_scout_lupetti_ritrovo = serata_scout_lupetti_ritrovo
        this.serata_scout_lupetti_ora_inizio = serata_scout_lupetti_ora_inizio
        this.serata_scout_lupetti_ora_fine = serata_scout_lupetti_ora_fine
        this.serata_scout_lupetti_link_form = serata_scout_lupetti_link_form
        this.serata_scout_esploratori = serata_scout_esploratori
        this.serata_scout_esploratori_ritrovo = serata_scout_esploratori_ritrovo
        this.serata_scout_esploratori_ora_inizio = serata_scout_esploratori_ora_inizio
        this.serata_scout_esploratori_ora_fine = serata_scout_esploratori_ora_fine
        this.serata_scout_esploratori_link_form = serata_scout_esploratori_link_form


        this.castori_inizio = castori_inizio
        this.castori_fine = castori_fine
        this.castori_ritrovo = castori_ritrovo
        this.castori_osservazioni = castori_osservazioni
        this.lupetti_inizio = lupetti_inizio
        this.lupetti_fine = lupetti_fine
        this.lupetti_ritrovo = lupetti_ritrovo
        this.lupetti_osservazioni = lupetti_osservazioni
        this.esploratori_inizio = esploratori_inizio
        this.esploratori_fine = esploratori_fine
        this.esploratori_ritrovo = esploratori_ritrovo
        this.esploratori_osservazioni = esploratori_osservazioni
        this.pionieri_inizio = pionieri_inizio
        this.pionieri_fine = pionieri_fine
        this.pionieri_ritrovo = pionieri_ritrovo
        this.pionieri_osservazioni = pionieri_osservazioni


    }
}