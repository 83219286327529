import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapComponent } from './components/map/map.component';
import { ZoneDisplayComponent } from './components/zone-display/zone-display.component';
import { ZonaDisplayComponent } from './components/zona-display/zona-display.component';

import { STiDiventareScoutRoutingModule } from './sti-diventare-scout-routing.module';
import { DataSezioniService } from './services/data-sezioni.service';
import { SharedModule } from '../shared/shared.module';
import { SerataScoutComponent } from './components/serata-scout/serata-scout.component';


@NgModule({
  declarations: [
    MapComponent,
    ZoneDisplayComponent,
    ZonaDisplayComponent,
    SerataScoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    STiDiventareScoutRoutingModule
  ],
  providers: [DataSezioniService]
})
export class STiDiventareScoutModule { }
