export class Zona {
    name: Zona_ID
    description: string
    icon: string
    imgPath: string
    styleColor: string

    constructor(obj: Zona = {} as Zona) {
        let {
            name = <Zona_ID>'',
            description = '',
            icon = '',
            imgPath = '',
            styleColor = ''
        } = obj;
        this.name = name;
        this.description = description;
        this.icon = icon;
        this.imgPath = imgPath;
        this.styleColor = styleColor;
    }
}

export type Zona_ID = 'luganese' | 'mendrisiotto' | 'bellinzonese' | 'locarnese' | '' | 'all'