import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild, HostListener, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import Headroom from "headroom.js";
import { environment } from 'src/environments/environment';
//declare gives Angular app access to ga function
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private renderer: Renderer2, private router: Router, @Inject(DOCUMENT,) private document: any,
        @Inject(PLATFORM_ID) private platformId: any) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if(isPlatformBrowser(this.platformId))
                {
                  gtag('config', environment.ga_id, {'page_path': event.urlAfterRedirects, 'page_location':  event.urlAfterRedirects, 'debug_mode':!environment.production });
                }
               
            }
        })


    }

    ngOnInit(){
        var headroom = new Headroom(this.document.querySelector("#navbar-main"), {
          offset: 300,
          tolerance: {
            up: 30,
            down: 30
          },
        });
        headroom.init();
      }
}
