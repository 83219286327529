import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'diventare-scout', loadChildren: () => import('./sti-diventare-scout/sti-diventare-scout.module').then(m => m.STiDiventareScoutModule) },
  { path: 'scoutismo', loadChildren: () => import('./sti-scoutismo/sti-scoutismo.module').then(m => m.STiScoutismoModule) },
  { path: 'associazione', loadChildren: () => import('./sti-associazione/sti-associazione.module').then(m => m.STiAssociazioneModule) },
  { path: '', redirectTo: 'diventare-scout', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
