import { Component, OnInit } from '@angular/core';
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
import { DataSezioniService } from '../../services';
import { SezioneMap } from '../../models';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  private map: mapboxgl.Map;
  private sedi: SezioneMap[];
  constructor(private dataSezioni: DataSezioniService) {
    this.sedi = dataSezioni.getSezioniMap();
  }

  ngOnInit(): void {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYXJjaGltZWRlLXN0aSIsImEiOiJja2R2czMyYnowNXQ4MzBvYW9qMGpjbHpzIn0.02POOs_ZzBf06UzEjcZ4nQ';
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
      center: [8.901553, 46.139702], // starting position [lng, lat]
      zoom: 10 // starting zoom
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
    );
    this.populateMap()
  }

  populateMap() {
    this.sedi.forEach(s => {
      const colors = {
        "luganese": "#0087CC",
        "mendrisiotto": "#554596",
        "bellinzonese": "#AE0F0A",
        "locarnese": "#FBBA00",
        "sti": "#53Af32"
      };
      const marker = new mapboxgl.Marker({ color: colors[s.zona] })
        .setLngLat([s.longitude, s.latitude])
        .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML('<h4 class="text-' + s.zona + '">' + s.name + '</h4><p><a class="text-' + s.zona + '" ' + 'ng-reflect-router-link href="#/zona/' + s.zona + '#' + s.id + '">' + 'Dettagli</a></p>'))
        .addTo(this.map);
      //marker.getElement().className = marker.getElement().className.concat(' ', 'bg-gradient-', s.zona);
    });



  }

}
