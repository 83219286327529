import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { STiScoutismoRoutingModule } from './sti-scoutismo-routing.module';
import { ScopiScoutismoComponent } from './components/scopi-scoutismo/scopi-scoutismo.component';
import { StoriaScoutismoComponent } from './components/storia-scoutismo/storia-scoutismo.component';
import { BrancaCastoriComponent } from './components/branca-castori/branca-castori.component';
import { BrancaLupettiComponent } from './components/branca-lupetti/branca-lupetti.component';
import { BrancaEsploratoriComponent } from './components/branca-esploratori/branca-esploratori.component';
import { BrancaPionieriComponent } from './components/branca-pionieri/branca-pionieri.component';
import { BrancaRoverComponent } from './components/branca-rover/branca-rover.component';
import { SilverScoutComponent } from './components/silver-scout/silver-scout.component';
import { ScoutAdOgniCostoComponent } from './components/scout-ad-ogni-costo/scout-ad-ogni-costo.component';


@NgModule({
  declarations: [ScopiScoutismoComponent, StoriaScoutismoComponent, BrancaCastoriComponent, BrancaLupettiComponent, BrancaEsploratoriComponent, BrancaPionieriComponent, BrancaRoverComponent, SilverScoutComponent, ScoutAdOgniCostoComponent],
  imports: [
    CommonModule,
    STiScoutismoRoutingModule
  ]
})
export class STiScoutismoModule { }
