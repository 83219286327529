import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { MapComponent } from './components/map/map.component';
import { ZoneDisplayComponent } from './components/zone-display/zone-display.component';
import { ZonaDisplayComponent } from './components/zona-display/zona-display.component';
import { SerataScoutComponent } from './components/serata-scout/serata-scout.component';

const routes: Routes = [
  { path: 'home', component: ZoneDisplayComponent },
  { path: '', component: SerataScoutComponent }, //change here TODO 
  { path: 'map', component: MapComponent },
  {
    path: 'sezioni', component: ZonaDisplayComponent
  },
  {
    path: 'zona/:zona', component: ZonaDisplayComponent
  },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
  ],
})
export class STiDiventareScoutRoutingModule { }
