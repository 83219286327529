import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-silver-scout',
  templateUrl: './silver-scout.component.html',
  styleUrls: ['./silver-scout.component.css']
})
export class SilverScoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
